<template>
  <b-row no-gutters
    ><b-col cols="12" class="d-inline-flex align-items-center text-left"
      ><div class="circled-number mr-3">2</div>
      <span class="green text-uppercase bold larger text-shadow"
        >{{ $t("events.olympics.tutorial.step") }} 2:
        {{ $t("events.olympics.tutorial.page_1.step_2.heading") }}</span
      ></b-col
    ><b-col cols="5" class="text-left mt-5 ml-5"
      ><p class="text-white medium large">
        {{ $t("events.olympics.tutorial.page_3.par_1") }}
      </p>
      <p class="text-white medium large">
        {{ $t("events.olympics.tutorial.page_3.par_2") }}
      </p>
      <p class="green medium large">
        {{ $t("events.olympics.tutorial.page_3.par_3") }}
      </p></b-col
    ></b-row
  >
</template>
<script>
export default {
  name: "ModalOlympiaTutorialPage3",
};
</script>
